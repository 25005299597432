import {
  InstagramIcon,
  LinkedinIcon,
  WebsiteIcon,
  NewsIcon,
  WhatsAppIcon,
} from './socialMediaIcons'

export const SOCIAL_MEDIAS = [
  {
    id: 1,
    name: 'Sitio Web',
    link: 'https://www.nestconsultores.cl',
    icon: WebsiteIcon,
  },
  {
    id: 2,
    name: 'WhatsApp',
    link: 'https://wa.me/+56948590340',
    icon: WhatsAppIcon,
  },
  {
    id: 3,
    name: 'LinkedIn',
    link: 'https://linkedin.com/company/nest-consultores',
    icon: LinkedinIcon,
  },
  {
    id: 4,
    name: 'Instagram',
    link: 'https://instagram.com/nestconsultores',
    icon: InstagramIcon,
  },
  {
    id: 5,
    name: 'Noticias',
    link: 'https://nestconsultores.cl/blog',
    icon: NewsIcon,
  },
]
