import { Box, Stack, Typography } from '@mui/material'
import Logo from '../../../assets/logo-nest.png'
import { SocialMediaCard } from '../../SocialMediaCard/SocialMediaCard'
import { SOCIAL_MEDIAS } from '../../../utils/socialMedia'
import LogoNestCircle from '../../../assets/logo-nest-redondo.png'

export const Home = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#303030',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: 500,
            px: 3,
            py: '50px',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={LogoNestCircle}
              alt="NEST Consultores"
              className="img-rounded"
            />
          </Box>
          <Stack spacing={1} sx={{ my: 3, textAlign: 'center' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              Redes Sociales
            </Typography>
            <Typography color="white" fontWeight="100" fontSize="16px">
              Acá podrás encontrar todas las Redes Sociales y Sitios de Interés
              de NEST Consultores &nbsp;
            </Typography>
          </Stack>

          <Stack spacing={3} marginBottom="100px">
            {SOCIAL_MEDIAS.map(({ id, name, link, icon }) => (
              <SocialMediaCard key={id} name={name} link={link} icon={icon} />
            ))}
          </Stack>
        </Box>
      </Box>
      <Stack
        spacing={1}
        sx={{
          mb: 3,
          background: 'white',
          width: '100%',
          '@media (min-width: 900px)': {
            width: '40%',
            margin: '0 auto',
          },
        }}
      >
        <img src={Logo} alt="NEST Consultores" className="img-login" />
      </Stack>
    </>
  )
}
