import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

export const SocialMediaCard = ({ name, link, icon }) => {
  console.log(link)
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        py: '10px',
        cursor: 'pointer',
        height: '80px',
      }}
      onClick={() => (window.location.href = link)}
    >
      <CardMedia
        component="img"
        sx={{ width: 50, height: 50 }}
        image={icon}
        alt={name}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography component="div" variant="h5">
          {name}
        </Typography>
        <Typography fontSize="14px" color="text.secondary">
          {link}
        </Typography>
      </Box>
    </Card>
  )
}
