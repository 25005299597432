import { Navigate, Route, Routes } from 'react-router-dom'
import { Home } from './components/pages/home/Home'
import './App.css'
import { HomePortalAccess } from './components/pages/HomePortalAccess/HomePortalAccess'
const App = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/2" element={<HomePortalAccess />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  )
}

export default App
